<template>
  <el-dialog :visible.sync="visible" width="1200px" destroy-on-close @closed="$emit('closed')">
    <template #title>
      <div class="dialog-header" :class="orderInfo.OrderStatus == 5 || orderInfo.OrderStatus == 6 || orderInfo.OrderStatus == 7 ? 'red' : 'blue'">
        <div class="icon img-cover">
          <img v-if="orderInfo.OrderStatus == 5 || orderInfo.OrderStatus == 6 || orderInfo.OrderStatus == 7" src="../assets/pc-images/order-detail-red-icon.png" />
          <img v-else src="../assets/pc-images/order-detail-icon.png" />
        </div>
        <div class="detail">
          <div class="title">{{ orderStatusObj[orderInfo.OrderStatus] }}</div>
          <div class="sub-title">
            订单{{ orderStatusObj[orderInfo.OrderStatus] }}
            <span v-if="orderInfo.OrderStatus != 4 && orderInfo.OrderStatus != 6 && orderInfo.OrderStatus != 7">，请耐心等待...</span>
          </div>
        </div>
      </div>
    </template>
    <div class="order-detail-container">
      <div class="title-item">订单信息</div>
      <div class="table-item">
        <div class="tr table-head">
          <div class="td">订单状态</div>
          <div class="td">订单编号</div>
          <div class="td">支付金额</div>
          <div class="td">下单时间</div>
          <div class="td">完成时间</div>
        </div>
        <div class="tr table-body">
          <div class="td">
            <span :class="'status-' + orderInfo.OrderStatus">{{ orderStatusObj[orderInfo.OrderStatus] }}</span>
          </div>
          <div class="td">{{ orderInfo.OrderSN }}</div>
          <div class="td">{{ orderInfo.Amount }}</div>
          <div class="td">{{ orderInfo.CreateAt ? $TOOL.dateFormat(orderInfo.CreateAt * 1000, 'yyyy-MM-dd hh:mm') : '…' }}</div>
          <div class="td">{{ orderInfo.CompleteTime ? $TOOL.dateFormat(orderInfo.CompleteTime * 1000, 'yyyy-MM-dd hh:mm') : '…' }}</div>
        </div>
      </div>
      <div class="table-item">
        <div class="tr table-head">
          <div class="td goods-info">商品详情</div>
          <div class="td">下单数量</div>
          <div class="td">订单金额</div>
          <div class="td">下单参数</div>
          <div class="td">操作</div>
        </div>
        <div class="tr table-body">
          <div class="td goods-info">
            <div class="img img-cover">
              <img v-if="orderInfo.Img" :src="orderInfo.Img" />
              <img v-else src="../assets/images/no-image-icon.png" />
            </div>
            <div class="name">{{ orderInfo.GoodsName }}</div>
          </div>
          <div class="td">{{ orderInfo.BuyNumber }}</div>
          <div class="td">{{ orderInfo.Amount }}</div>
          <div class="td params">
            <div v-for="(params, paramsIndex) in orderInfo.BuyParams" :key="paramsIndex" class="item">
              <div class="val">{{ params.value }}</div>
              <div class="copy" @click="handleCopy(params.value)">复制</div>
            </div>
          </div>
          <div class="td operate">
            <router-link :to="{ path: '/goodsDetail', query: {id: orderInfo.GoodsId} }" class="btn a">再次下单</router-link>
            <div class="btn" v-if="orderInfo.CanTui && orderInfo.CanTui.includes(`${orderInfo.OrderStatus}`) && orderInfo.OrderStatus != 4 && orderInfo.OrderStatus != 5 && orderInfo.OrderStatus != 6 && orderInfo.OrderStatus != 7" @click="handleAppRefund">申请退款</div>
          </div>
        </div>
      </div>
      <div class="title-item">订单记录：</div>
      <div class="record-list">
        <div v-for="(item, index) in orderInfo.Logs" :key="index" class="item">
          <div class="name">{{ item.createdAt }}</div>
          <div class="value">{{ item.content }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="title-item">订单进度：</div>
      <div class="count-item">
        <div class="item">下单数量：<span>{{ orderInfo.BuyNumber }}</span></div>
        <div class="item">执行数量：<span>{{ orderInfo.CurrentNum - orderInfo.StartNum }}</span></div>
        <div class="item">初始数量：<span>{{ orderInfo.StartNum }}</span></div>
        <div class="item">当前数量：<span>{{ orderInfo.CurrentNum }}</span></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  emits: ['success', 'closed'],
  name: 'PcOrderDetail',
  data() {
    return {
      visible: false,
      orderInfo: {},
      orderStatusObj: { '-1': '待付款', '1': '处理中', '2': '处理中', '3': '处理中', '4': '已完成', '5': '退单中', '6': '已退单', '7': '已退款', '8': '处理中' }
    }
  },
  mounted() {

  },
  methods: {
    open(id) {
      this.visible = true
      this.getOrderDetail(id)
      return this
    },
    async getOrderDetail(id) {
      var res = await this.$API.orderDetail.post({
        Id: id
      })
      if (res.error == 0) {
        res.info.Logs = JSON.parse(res.info.Logs || '[]')
        res.info.BuyParams = JSON.parse(res.info.BuyParams || '[]')
        res.info.CardCodeIds = res.info.CardCodeIds.split(',')
        res.info.CardCodeIds = res.info.CardCodeIds.filter(item => item != '')
        res.info.CanTui = res.info.CanTui ? res.info.CanTui.split(',') : []
        this.orderInfo = res.info
      }
    },
    async handleAppRefund() {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '申请退款',
        message: '确定要申请退款吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(async () => {
        const { orderInfo } = this
        var res = await this.$API.appRefund.post({
          Id: orderInfo.Id
        })
        if (res.error == 0) {
          this.$toast.success(res.info)
          this.getOrderDetail(orderInfo.Id)
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
        }
      }).catch(() => {
      })
    },
  }
}
</script>

<style scoped lang="less">

/deep/.el-dialog {
  border-radius: 12px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
  }
}
.dialog-header {
  border-radius: 12px 12px 0 0;
  position: relative;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    height: 4px;
    top: 0;
    left: 20px;
    right: 20px;
  }
  &.blue {
    background-color: #f0eeff;
    &::after {
      background-color: #6a5ffe;
    }
  }
  &.red {
    background-color: #ffefee;
    &::after {
      background-color: #f00;
    }
  }
  .icon {
    width: 60px;
    height: 60px;
    margin-left: 20px;
  }
  .detail {
    margin-left: 30px;
    line-height: 30px;
    font-weight: bold;
    .title {
      color: #0b212c;
      font-size: 18px;
    }
    .sub-title {
      color: #71757d;
      font-size: 16px;
    }
  }
}
.order-detail-container {
  .title-item {
    line-height: 28px;
    font-weight: 500;
    font-size: 18px;
    color: #3d3d42;
    margin-bottom: 20px;
  }
  .table-item {
    border: 1px solid #eff4ff;
    margin-bottom: 20px;
    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 48px;
      text-align: center;
      &.table-head {
        background-color: #eff4ff;
      }
      &.table-body {
        align-items: stretch;
        .td {
          display: flex;
          justify-content: center;
          align-items: center;
          &.goods-info {
            width: 251px;
            margin-right: 0;
            .img {
              margin: 16px 10px 16px 0;
              width: 100px;
              height: 100px;
            }
            .name {
              flex: 1;
              font-size: 14px;
              line-height: 20px;
              color: #333;
              font-weight: bold;
              text-align: left;
            }
          }
          &.params {
            flex-direction: column;
            .item {
              color: #999;
              display: flex;
              justify-content: center;
              align-items: center;
              .val {
                flex: 1;
              }
              .copy {
                white-space: nowrap;
                margin-left: 6px;
                cursor: pointer;
                margin-right: 6px;
                padding: 0 6px;
                border-radius: 4px;
                background-color: rgba(106, 95, 254, .12);
                font-size: 12px;
                color: #6a5ffe;
              }
            }
          }
          &.operate {
            flex-direction: column;
            .btn {
              cursor: pointer;
              margin: 4px 0;
              color: #6a5ffe;
              &.a {
                width: 76px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 6px;
                color: #fff;
                background-color: #6a5ffe;
              }
            }
          }
        }
      }
      .td {
        box-sizing: border-box;
        padding: 0 20px;
        &:nth-child(1) {
          width: 176px;
          margin-right: 75px;
        }
        &:nth-child(2) {
          width: 185px;
        }
        &:nth-child(3) {
          width: 185px;
        }
        &:nth-child(4) {
          flex: 1;
        }
        &:nth-child(5) {
          width: 180px;
        }

      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e9edf5;
    margin-bottom: 20px;
  }
  .record-list {
    margin-bottom: 20px;
    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      .name {
        color: #9fafc1;
        margin-right: 6px;
      }
      .value {
        color: #3d3d42;
      }
    }
  }
  .count-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    .item {
      color: #9fafc1;
      margin-right: 40px;
      span {
        color: #3d3d42;
      }
    }
  }
}
</style>
